import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import { color_textLink } from "_src/assets/data/global_colors"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import ContactForm from "_components/forms/form_contact"
import Footer from "_components//nav/footer/_footer"
import Header from "_components/nav/header/_header"
import PageHeadline from "_components/misc/pageHeadline"
import Seo from "_components/Seo/contact"
import {
  Paragraph,
  Screen,
  Content,
  HeaderContainer,
} from "_data/global_styles"

const Contact = (props) => {
  return (
    <>
      {!props.skipHeader && (
        <>
          <Seo />
          <HeaderContainer>
            <Header />
          </HeaderContainer>
        </>
      )}
      <Screen>
        <Content>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.65 }}
          >
            <PageHeadline copy="Contact Support" />
            <div style={{ marginLeft: "1vw", color: "#303c6c" }}>
              {!props.skipHeader && (
                <Paragraph>
                  If your question is not{" "}
                  <Link
                    style={{
                      color: color_textLink,
                      fontWeight: "800",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    to="/faq"
                  >
                    answered in the FAQ
                  </Link>
                  , please use the form below and I will happily get back to
                  you.
                </Paragraph>
              )}
            </div>
            <ContactForm skipHeader={props.skipHeader} />
          </motion.div>
        </Content>
        {!props.skipHeader && <Footer />}
      </Screen>
    </>
  )
}

export default Contact
